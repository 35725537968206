const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
  },
  {
    path: "/problem-solvers",
    name: "problemSolvers",
    component: () => import(/* webpackChunkName: "problemSolvers" */ "../views/ProblemSolvers.vue")
  },
  {
    path: "/audit-report",
    name: "auditReport",
    component: () => import(/* webpackChunkName: "auditReport" */ "../views/AuditReport.vue")
  },
  {
    path: "/help",
    name: "help",
    component: () => import(/* webpackChunkName: "help" */ "../views/Help.vue")
  },
  {
    path: "/auth-failed",
    name: "authFailed",
    component: () => import(/* webpackChunkName: "authFailed" */ "../views/AuthFailed.vue"),
    meta: {
      allowInsecure: true
    }
  }
];

export default routes;
